import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import { AuthorCard } from "../../components/site";
import { Container, Wrapper, Row, Box } from "../../components/util";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

const List = styled.ol`
  margin-top: 50px !important;
  li {
    margin-bottom: 50px;
  }

  ul {
    margin-bottom: 20px !important;
    margin-top: 10px !important;

    li {
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 20px !important;
  }
`;

const PlanChanges = (props) => (
  <Layout>
    <Seo
      title="Partner Plan Changes | FlexiTime"
      pathname={props.location.pathname}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container>
      <Wrapper stackGap={70} maxWidth={800}>
        <Box className="-textCenter" stackGap={20}>
          <h1 className="-fontLarge ">Important Partner Plan Changes</h1>
          <h4>Wed, 31 Aug, 2022</h4>
        </Box>
        <Row justify="center">
          <AuthorCard author="Rob" noBreak />
        </Row>

        <div className="font-bump">
          <p>
            I can’t believe it’s been six months since we turned off our old
            FlexiTime system and migrated the remaining users over to PayHero.
            Introducing a whole new payroll app came with its fair share of
            challenges, but overall we have been delighted with how well our
            customers have adopted PayHero. I can’t thank you enough for the
            help you’ve given us during that period of change.
          </p>
          <p>
            During the 10 year lifetime of FlexiTime payroll, we made a number
            of plan changes and we grandfathered old plans to try to minimise
            the impact on our customers. The downside of this was that our
            partner invoices became complex with many different plans and prices
            for your clients. Now that we have moved all our customers to
            PayHero, we will be simplifying our partner invoices over the next
            couple of months, as well as applying a price increase to some of
            the old pricing plans. I wanted to let you know about these changes
            now, to give you enough time to notify your clients of any price
            changes.
          </p>
          <List>
            <li>
              <p>
                The first change will be to remove the old FlexiTime partner
                programme discount that you may be seeing on your partner
                invoice. Any customers that had a FlexiTime legacy discount
                applied will be moved to your current partner status discount.
              </p>
              <ul>
                <li>Gold - 25%</li>
                <li>Silver - 20%</li>
                <li>Bronze - 15%</li>
              </ul>
              <p>
                This change will be applied to the invoice you receive on
                October 1st for the September period.
              </p>
            </li>
            <li>
              <p>
                We will be increasing the price of some of our old plans by 20%.
                These plans will currently appear on your invoice with the
                following plan names -
              </p>
              <ul>
                <li>PayHero | FT Partner Legacy Upgrade 2020</li>
                <li>PayHero | FlexiTime Legacy Upgrade</li>
                <li>PayHero Shift | FlexiTime Legacy Upgrade</li>
                <li>PayHero | Employee Tier | 1-5</li>
                <li>PayHero | Employee Tier | 6-10</li>
                <li>PayHero | Employee Tier | 11-20</li>
                <li>PayHero | Employee Tier | 21-40</li>
                <li>PayHero | Employee Tier | 40+</li>
              </ul>
              <p>
                These legacy plans have fallen well below our current list
                pricing. This is the first time we’ve changed these plans since
                we launched them, some going back to 2014. In most cases, the
                new pricing is still below our current list prices for the same
                level of functionality. However, I would suggest that you review
                our{" "}
                <a
                  href="https://www.payhero.co.nz/pricing"
                  target="_blank"
                  rel="noreferrer"
                >
                  pricing page
                </a>{" "}
                to check that your clients are on the best plan for the
                functionality they need.
              </p>
              <p>
                This change will take effect from October 1st, and the price
                increase will show on the partner invoice you receive on
                November 1st for the October period.
              </p>
              <p>
                If your clients pay for their FlexiTime subscription themselves,
                they’ll get an email from us directly.
              </p>
            </li>
            <li>
              <p>
                Xero has made changes to the Xero App Store beginning in August,
                which require us to pay Xero an ongoing 15% fee for
                subscriptions that are made through the Xero App Store, by
                clicking on the <i>Get This App</i> link in the App Store.
              </p>
              <p>
                Because we are required to pay this fee to Xero, accounts
                subscribed through the Xero App Store will not be eligible for a
                partner discount. Please make sure when you add new clients to
                PayHero that you do this from the partner dashboard rather than
                through the Xero App Store.
              </p>
            </li>
            <li>
              <p>
                From December 1st we will be dropping our old per payslip
                pricing plan. We will be moving these plans onto our standard
                per employee pricing plans, but will minimise the impact of this
                change on the overall cost to your clients by applying a per
                client discount. We will provide further information about these
                changes closer to the time.
              </p>
            </li>
          </List>
          <p>
            We understand that you are facing price increases in many different
            areas at the moment, as are we, with high inflation and exchange
            rates impacting on wage costs, software expenses, and hosting costs,
            to name a few. We’ve tried to minimise the impact of these changes
            to only those customers on old plans that are now well below the
            current list price for the new PayHero app.
          </p>
          <p>
            We will continue to develop and improve our apps and systems. We’ve
            recently released{" "}
            <a href="https://www.droppah.com" target="_blank" rel="noreferrer">
              Droppah
            </a>
            , our rostering app, and have made it available for free to
            businesses with fewer than 10 employees, to help ease the strain on
            small hospo and retail businesses. Earlier this month we also
            released our new app{" "}
            <a href="https://www.karmly.io" target="_blank" rel="noreferrer">
              Karmly
            </a>
            , to help independent contractors manage their time and expenses,
            workload and client invoicing. This app is completely free and will
            remain free to contractors because we want our customers and
            partners to have Work.Life.Success.™
          </p>
          <p>
            If you have any questions about these changes, please email{" "}
            <a
              href="mailto:partners@flexitime.works"
              target="_blank"
              rel="noreferrer"
            >
              partners@flexitime.works
            </a>{" "}
            and our partner team will be happy to assist. We very much
            appreciate the feedback we get from our partners about how we can
            make life better for your clients.
          </p>
          <p css={{ marginTop: "70px", marginBottom: "-30px !important" }}>
            Kind Regards,
          </p>
        </div>
        <AuthorCard author="Rob" />
      </Wrapper>
    </Container>
  </Layout>
);

export default PlanChanges;
